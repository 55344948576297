<template>
  <header id="dssp-header" class="whiteBack">
    <nav class="navbar navbar-expand-md bg-dark" id="bspd-header" aria-labelledby="bspdHeaderLabel" v-if="companyowner == 4 && isBrightspeedToggleOn == 'true'">
      <div class="container-fluid">
        <span class="navbar-text mx-auto my-auto" id="bspd-text">
          Hello. Your service provider is now Brightspeed. (<a id="bspd-url" :href="BrightspeedURL">See details</a>)
        </span>
      </div>
    </nav>
    <nav class="navbar navbar-expand-md bg-body" id="top-header" aria-labelledby="topHeaderLabel">
      <div class="ctl-logo">
        <a :href="ctlLogoURL" alt="centurylink-logo">
          <img class="img-specs" src="../assets/images/CtlLogo.svg" alt="ctl-logo">
        </a>
      </div>
      <div class="container me-0" id="mobile-icons">
        <a class="hidden chatbot navbar-toggler" id="chatbot-icon" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat">
          <img id="chat-icon" src="../assets/images/icon-chat.svg" alt="Chat Support" data-galink-type="chat">
        </a>
        <button id="hamburger-icon" class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar" aria-controls="sidebar"><em class="material-icons">menu</em></button>
      </div>
      <div class="collapse navbar-collapse" id="search-menu">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link font-header" :href="AuthSignOut" @click="resetData()" id="sign-out">Sign Out</a>
          </li>
        </ul>
        <form role="search">
          <img tabindex="0" class ="search-icon" src="../assets/images/icon-action-search_24px.png" label="Toggle" @click="toggle" v-on:keyup.enter="toggle" alt="search-icon"/>
          <OverlayPanel ref="op" :dismissable="true" >
            <div class="search-component">
              <img class ="search-icon-pop" src="../assets/images/icon-action-grey-search_24px.png" @click="onSearchClick()" alt="search-icon-pop"/>
              <input ref="searchBox" class="search-box whiteBack font-header" v-model="Address" @keypress="searchbar" placeholder="Search bar"/>
            </div>
          </OverlayPanel>
        </form>
      </div>

      <div class="navbar-toggler offcanvas offcanvas-end" tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel">
        <div class="offcanvas-header">
          <img id="mobile-search-icon" class ="cursor-pointer" v-if="showSearchIcon" src="../assets/images/icon-action-search_24px.png"  @click="onSearchIcon()" alt="mobile-search-icon"/>
          <img class ="left-arrow cursor-pointer" v-if="showBackTick" src="../assets/images/back-arrow.png" @click="onBackTick()" alt="back-arrow"/>
          <button type="button" id="sidebar-close" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="onCrossClick()"></button>
        </div>
        <div class="mobile-search mt-2 mb-4" v-if="showSearchBar">
          <img id="mobile-search-popup-icon" class ="search-icon-pop" src="../assets/images/icon-action-search_24px.png" @click="onSearchClick()" alt="mobile-search-icon-pop"/>
          <input id="mobile-search-bar" class="whiteBack font-header" v-model="Address" @keypress="searchbar" placeholder="Search bar"/>
        </div>
        <div class="offcanvas-body pt-0 pb-0">
          <ul class="list-group list-group-flush">
            <li><a :href="AuthHome" class="d-flex align-items-start font-header sidebar-first-item">Home</a></li>
            <li><a :href="AuthBillingWithToken" class="d-flex align-items-start font-header sidebar-item">Billing</a></li>
            <li><a :href="AuthServices" class="d-flex align-items-start font-header sidebar-item">Services</a></li>
            <li>
              <a v-if="companyowner == 4 && isBrightspeedToggleOn == 'true'" :href="AuthBspdSupport" class="d-flex align-items-start font-header sidebar-item">Support</a>
              <a v-else :href="AuthSupport" class="d-flex align-items-start font-header sidebar-item">Support</a>
            </li>
            <li><a :href="AuthSettings" class="d-flex align-items-start font-header sidebar-last-item">Settings</a></li>
            <div class="h-divider"></div>
            <li><a :href="AuthSignOut" @click="resetData()" class="d-flex align-items-start font-header sidebar-sign-out">Sign Out</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-expand-md bg-body" id="bottom-header" aria-labelledby="bottomHeaderLabel">
      <div class="collapse navbar-collapse" id="bottom-header-list">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link font-header bottom-header-items" :href="AuthHome" id="">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-header bottom-header-items" :href="AuthBillingWithToken" id="">Billing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-header bottom-header-items" :href="AuthServices" id="">Services</a>
          </li>
          <li class="nav-item">
            <a v-if="companyowner == 4 && isBrightspeedToggleOn == 'true'" class="nav-link font-header bottom-header-items" :href="AuthBspdSupport" id="">Support</a>
            <a v-else class="nav-link font-header bottom-header-items" :href="AuthSupport" id="">Support</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-header bottom-header-items" :href="AuthSettings" id="">Settings</a>
          </li>
        </ul>
      </div>
      <div class="userid">
        <UserSwitch/>
      </div>
    </nav>
  </header>
</template>

<script>
import UserSwitch from '@/components/UserSwitch'
import { defineComponent } from 'vue';
import store from '../store';
import links from '../js/links';
import constants from "../js/constants";
import { GET_DATA, RESET as reset_session } from '../store/sessioncontants';
import { RESET as reset_local} from "../store/storecontants";

export default defineComponent({
  name: 'Header',
  components: {
    UserSwitch,
  }, 
  data() {
    return {
      Address: '',
      showSearchBar: false,
      showBackTick: false,
      showSearchIcon: true,
      companyOwnerId:  this.$store.getters[GET_DATA]('companyOwnerId'),
      isBrightspeedToggleOn: false,
      BrightspeedURL: links.BrightspeedURL,
      ctlLogoURL: links.ctlLogoURL,
      AuthHome: links.AuthHome,
      AuthBilling: links.AuthBilling,
      AuthServices: links.AuthServices,
      AuthBspdSupport: links.AuthBspdSupport,
      AuthSupport: links.AuthSupport, 
      AuthSettings: links.AuthSettings,
      AuthSignOut: constants.AzureLogout,
      AuthBillingWithToken: links.AuthBillingWithToken
    }
  },
  methods: {
    resetData () {
      this.$store.dispatch(reset_session);
      this.$store.dispatch(reset_local);
    },
    toggle (event) {
      this.$refs.op.toggle(event)
      setTimeout(() => { 
        let errorDiv = this.$refs.searchBox
        if(errorDiv) {
          this.$refs.searchBox.focus();
        }
      }, 10)
    },
    searchbar: function (e) {
      if (e.keyCode === 13) {
        this.onSearchClick()
      }else if (e.keyCode === 37 || e.keyCode === 47) {
        //to restrict % and / in search
        e.preventDefault();
      }
    },
    onSearchIcon () {
      this.showSearchBar = true
      this.showSearchIcon = false
      this.showBackTick = true
    },
    onBackTick () {
      this.showSearchBar = false
      this.showSearchIcon = true
      this.showBackTick = false
    },
    onSearchClick () {
      this.$gtm.trackView('Searchbar from header', 'DigitalHome')
      window.dataLayer.push({
        event: 'gtm.click',
        action: 'click',
        label: 'In Support page Search function from header',
        page: {
          pageType: '<Searchbar>',
          pageName: 'Support'
        },
        value: this.Address,
        noninteraction: false
      })
      if (this.Address.length !== 0) {
        const searchQuery = this.Address.replace(/[\\$+?#{|[.()*]+/gm, '')
        const link = process.env.VUE_APP_CTL_STATIC_DOMAIN + '/home/search.' + searchQuery + '.html?search=' + searchQuery + '&search_submit=&start=0&limit=25&feed=residential'
        window.open(link, '_blank')
      }
    },
    onCrossClick () {
      this.showSearchBar = false
      this.showBackTick = false
      this.showSearchIcon = true
    },
    isOwnerIdMatching (ownerIdChecking) {
      this.companyOwnerId = ownerIdChecking
      this.isBrightspeedToggleOn = process.env.VUE_APP_TSA_TOGGLE
      if(this.isBrightspeedToggleOn == 'true' && this.companyOwnerId == 4) {
        window.dataLayer.push({
          company: 'brightspeed'
        });
      } else {
        window.dataLayer.push({
          company: 'centurylink'
        });
      }
    }
  },
  computed: {
		companyowner () {
			this.isOwnerIdMatching(this.$store.getters[GET_DATA]('companyOwnerId'))
			return this.$store.getters[GET_DATA]('companyOwnerId')
		}
	}
});

</script>

<style scoped>
  #bspd-header {
    width: 100%;
    height: 24px;
    padding: 0;
  }

  #bspd-text {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 16px;
    color: white;
    padding: 0;
  }

  #bspd-url {
    color: white;
    text-decoration: none;
  }

  #bspd-url:hover {
    color: white!important;
    text-decoration: underline;
  }

  #mobile-icons {
    width: fit-content;
  }

  #chat-icon {
    height: 24px;
  }

  #chatbot-icon {
    margin-top: 22px;
  }

  .accordion-body {
    padding: 0.25rem 1.25rem 1rem 1.25rem;
  }

  .accordion-body ul {
    box-shadow: inset 2px 0 0px -1px rgb(0 0 0 / 13%);
  }

  #sidebar.navbar-toggler{
    font-size: 14px;
    padding: 0px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  #mobile-search-bar {
    margin: 0px;
    padding: 18px 16px 15px 56px;
    height: 52px;
    border: 1px solid black;
    border-radius: 4px;
    opacity: 1;
    width: 90%;
  }

  #mobile-search-popup-icon {
    position: absolute;
    left: 35px;
    top: 79px;
  }

  .left-arrow{
    width: 24px;
    height: 24px;
  }

  #mobile-search-icon {
    height: 24px;
    width: 24px;
    margin: 0px;
  }

  #sidebar-close.btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 1;
  }

  #sidebar-close.btn-close:focus{
    box-shadow: none;
  }

  .h-divider{
    margin-top:5px;
    margin-bottom:5px;
    height:1px;
    width:100%;
    border-top:1px solid rgba(0, 0, 0, 0.125);
  }

  .sidebar-button.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .sidebar-button.accordion-button {
    box-shadow: none!important;
  }

  .sidebar-button.accordion-button:not(.collapsed) {
    color: rgb(0,0,0);
    background-color: inherit;
    padding-bottom: 0.25rem;
  }

  .header-dropdown {
    width: 189px!important;
    padding: 0px 11.5px;
    border-top: 1px solid #BEBEBE;
    border-radius: 0px;
  }

  ul .header-dropdown li {
    border-top: 1px solid #BEBEBE;
  }

  a.dropdown-item:hover {
    text-decoration: none;
    background: #FFFFFF;
  }

  #hamburger-icon{
    border: none;
    padding: 0px 12px;
    margin-top: 27px;
  }
  div.navbar-brand {
    padding: 0px;
    margin: 0px;
  }

  #sign-out {
    padding: 0px 0px 0px 32px;
    margin-top: 32px;
  }

  .search-box{
    width:90%;
    margin-left: 24px;
    border: 1px solid black;
    border-radius: 4px;
    opacity: 1;
    padding: 14px 24px 12px 14px;
  }

  .search-icon-pop{
    height: 24px;
    width: 24px;
  }

  .search-icon{
    height: 24px;
    width: 24px;
    margin-top: 28px;
    margin-right:24px;
    margin-left: 24px;
    opacity: 1;
  }

  #top-header {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  #top-header .dropdown-menu {
    box-shadow: 3px 3px 3px #0000004d;
    margin-top: 10px;
  }

  #bottom-header {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 77px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
  }

  li a {
    display: block;
    color: black;
    padding: 14px 16px;
    text-decoration: none;
  }

  #dssp-header {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0px 2px 4px #00000033;
  }

  .navbar {
    font-size: 14px;
    font-weight: normal !important;
    color: black !important;
  }

  a:hover {
    text-decoration: none;
    color: rgb(0,0,0)!important;
  }

  .nav-link:focus {
    color: rgb(0,0,0);
  }

  .navbar-nav .dropdown-menu {
    width: 288px;
  }

  @media only screen and (max-width: 460px) {
    #bspd-text {
      font-size: 12px
    }
  }

  @media only screen and (max-width: 767px) {

    .search-box{
      margin: 0px;
      padding: 18px 16px 15px 56px;
      height: 52px;
    }

    #dssp-header {
      width: auto;
    }

    #bottom-header {
      height: 56px;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    #sidebar {
      width: 356px;
    }

    .userid{
      padding: 0.75rem;
      display: inline-flex;
    }

  }

  @media (min-width: 768px) {
    
    #top-header li:first-child {
      border-top: 0px;
    }

    .search-box{
      width:90%;
      margin-left: 16px;
      margin-top: 0px;
      padding: 14px 24px 12px 14px
    }

    .search-component{
      position: relative;
    }
    
    .search-component img{
      position: absolute;
      right:20px;
      top:14px;
    }

    .bspd-header .message {
      position: relative;
      left: 225px;
      top: 13px;
      font-size: 15px;
      font-weight: bold;
      font-family: MaisonNeue-Medium,sans-serif;
    }

    .img-specs{
      width: 139px;
      height: 28px;
      margin-left: 24px;
      margin-top:24px;
    }

    .userid{
      padding-top: 40px;
    }
  
  }

  .sidebar-item, .sidebar-first-item {
    padding-bottom: 18px;
  }

  .sidebar-item, .sidebar-last-item {
    padding-top: 18px;
  }

  .sidebar-last-item {
    padding-bottom: 36px;
  }

  .sidebar-sign-out {
    padding-top: 36px;
  }

  .sidebar-item, .sidebar-first-item, .sidebar-last-item, .sidebar-sign-out {
    padding-left: 19px;
  }

  .offcanvas {
    height: 870px;
    background-clip: inherit;
    border-radius: 0%;
  }

  .offcanvas-body{
    padding-left: 0px;
    padding-right: 0px;
  }

  .bottom-header-items{
    padding: 33px 80px 24px 0px !important;
  }

  #bottom-header-list{
    margin-left: 24px;
  }

  .ctl-logo{
    display: contents;
  }
   @media (min-width: 620px) and (max-width: 768px){
     .ctl-logo{
       display: flex;
     }
   }
    @media (min-width: 319px) and (max-width: 700px){
     .ctl-logo{
       display: block;
     }
   }

   .img-specs{
      margin-left: 24px;
      margin-top:24px;
    }

    .userid{
      margin-left: auto;
      padding-right: 24px;
    }

</style>