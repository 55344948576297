<template>
  <AuthHeader v-if="authFlow"/>
  <Header v-else/>
  <loader v-if="showLoading"/>
  <router-view/>
  <FooterDivider/>
  <Footer/> 
</template>

<script>
  import AuthHeader from '@/components/AuthHeader';
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';
  import FooterDivider from '@/components/FooterDivider.vue'
  import { RESET, GET_DATA, SET_DATA } from "./store/storecontants";
  import Loader from './components/Loader.vue';
  import store from './store';
  export default {
    components: {
      AuthHeader,
      Header,
      Footer,
      FooterDivider,
      Loader
    },
    data() {
      return {
        authFlow: false
      };
    },
    mounted() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});

      window.addEventListener('popstate', this.popstateEventAction );  
      
      if((window.location.href.indexOf('autopay') > -1) || (window.location.href.indexOf('wallet') > -1) || (window.location.href.indexOf('postbill') > -1) || (window.location.href.indexOf('billdispute') > -1) || (window.location.href.indexOf('pa/postPAIdentify') > -1)) {
        this.authFlow = true;
      } else if((window.location.href.indexOf('qbp') > -1) || (window.location.href.indexOf('pa') > -1)) {
        this.authFlow = false;
      }
    },
    computed: {
      showLoading () {
        return store.getters[GET_DATA]('showLoading')
      }
  },
  methods: {
     popstateEventAction() {
        if((window.location.href.indexOf('qbp') !== -1 )){
          if(window.location.href.indexOf('qbp/multiAccount') !== -1) {
            this.$router.push("/qbp/multiAccount");
          }else {
            this.$router.push("/qbp/identify");
            this.$store.dispatch(RESET);
            history.pushState(null, null, location.href);
          }
        }else if(window.location.href.indexOf('pa') !== -1){
          if(window.location.href.indexOf('pa/multiAccount') !== -1) {
            this.$router.push("/pa/multiAccount");
          }else {
            this.$router.push("/pa/identify");
            this.$store.dispatch(RESET);
            history.pushState(null, null, location.href);
          }
        }
      }
  }
}
</script>

<style>
#app {
  font-family: Maison Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
