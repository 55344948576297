import axios from 'axios'
import constants from "./constants";
import store from "../store";
import {
    GET_DATA, SET_DATA, ATTRIBUTE_RESET
} from "../store/sessioncontants";

const decrypt = async function (p1: string, p2: string | null) {
    try {
        // setting params
        let params = {};
        if(p2 != null){
            params = {
                p1: p1,
                p2: p2
            };
        } else {
            params = {
                p1: p1
            };
        }
        
        // api call with params
		const response =  await axios.get(constants.WalledGardenDecrypt, { params });
        
        //setting storage
        const attributesData = new Map();
        attributesData.set('wgData', JSON.stringify(response.data));
        if (response.data !== null && response.data.errorInfo == null || response.data.errorInfo.errorCode == "C360_ERROR_CODE_NULL_RESPONSE") {
            const wgAccData = response.data.wgName;
            if (wgAccData.includes('Notice') || wgAccData.includes('Postpaid')) {
                attributesData.set('accountType', 'postpaid');
            } else {
                attributesData.set('accountType', 'prepaid');
            }
            attributesData.set('telephoneNumber', response.data.wtn);
            if (response.data.customerIdentificationResponse.length == 1) {
                attributesData.set('customerIdentificationResponse', response.data.customerIdentificationResponse);
            }
        } else if (response.data == null) {
            attributesData.set('wgError', "Error decrypting data");
        } else {
            attributesData.set('wgError', JSON.stringify(response.data.errorInfo));
        }
        store.dispatch(SET_DATA, {attributes:attributesData});
    } catch (e) {
        console.error("Error calling jumpIn");
    }
}

export default {
    decrypt
}