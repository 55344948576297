<template>
  <div id="footer-border"></div>
</template>

<script>
export default {
    name: "FooterDivider",
}
</script>

<style>
#footer-border{
    display: flex;
    height: 9px;
    background-image: linear-gradient(to right, #001E60 15%, #0047BB 15%, #0047BB 50%, #48D597 50%);
}
@media (min-width: 768px) {
    #footer-border {
        height: 6px;
    }
}
</style>