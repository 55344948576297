<template>
          <label for="selectUser" class="sr-only">Select account number</label>
          <select v-if="accountNumbersList && accountNumbersList.length > 0" id="selectUser" class="form select user-label  font-header" @change="onChange($event)">
            <option v-for="account in accountNumbersList" :value="account.pid" v-bind:selected="account.pid === currentlyManagingPId" :key="account">{{account.accountNumber}}</option>
            <option v-if="!this.profileId"  :value="accountNumber" v-bind:selected="true" >{{accountNumber}}</option>
            <option value="addAccount" id="addAcctDropSelection">Add Account</option>
          </select>
          <select v-else id="selectUser" class="form select user-label  font-header" @change="onChange($event)">
            <option value="addAccount" id="addAcctDropSelection">Add Account</option>
          </select>
          <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" position="bottom-left" />
</template>

<script>
import axios from 'axios'
import Toast from 'primevue/toast'
import constants from "../js/constants"
import { GET_DATA, SET_DATA } from "../store/sessioncontants"
import links from "../js/links";
export default {
  name: 'UserSwitch',
  data () {
    return {
      profileId: null,
      accountNumbersList: null,
      currentlyManagingPId: null,
      valuePid: null,
      accountNumber: null,
      DigiHomeUrl: links.AuthHome,
      AddAccount: links.AddAnAccountNumber
    }
  },
  components: {
    Toast
  },
  methods: {
    onChange (event) {
      this.valuePid = event.target.value
      if (event.target.value === 'addAccount') {
        window.open(this.AddAccount, '_self')
      } else if (event.target.value !== '') {
        this.val = event.target.value
        this.postrequest()
      }
    },
    async postrequest () {
      try {
        if (this.$store.getters[GET_DATA]("username") == null) {
          this.$toast.add({ severity: 'error', summary: 'Error', detail: 'We were unable to switch accounts. Please try again later.', life: 4000 })
        } else {
          const parameters = { loginName: this.$store.getters[GET_DATA]("username"), pid: this.valuePid }
          const response = await axios.post(constants.SetCurrentAccountURL, parameters)
          if (response.data === 'Success') {
            window.dataLayer.push({
              event: 'my_ctl_setCurrentAccount_confirm',
              action: 'post',
              flow: {
                name: 'my ctl: home'
              },
              label: '<setCurrentAccount_success>',
              noninteraction: false
            })
            window.location.href = this.DigiHomeUrl
          } else {
            window.dataLayer.push({
              event: 'my_ctl_error',
              flow: {
                name: 'my ctl: support'
              },
              action: 'post',
              label: '<setCurrentAccount_failure>',
              errorName: 'ctl|ma|account|home_error|setCurrentAccount_error',
              noninteraction: false
            })
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'We were unable to switch accounts. Please try again later.', life: 4000 })
          }
        }
      } catch (error) {
        window.dataLayer.push({
          event: 'my_ctl_error',
          flow: {
            name: 'my ctl: support'
          },
          action: 'post',
          label: '<setCurrentAccount_failure>',
          errorName: 'ctl|ma|account|home_error|setCurrentAccount_error',
          noninteraction: false
        })

        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'We were unable to switch accounts. Please try again later.', life: 4000 })
      }
    },
    async getAccountNumbers() {
      try {
        const attributesData = new Map();
        const checkHeaderResponse = await axios.get(constants.AzureIsAuth)
        if (checkHeaderResponse.data) {
          attributesData.set('isAuth', true);
          this.$store.dispatch(SET_DATA, {attributes:attributesData});
          const checkAttrsResponse = await axios.get(constants.AzureAttrs)
            if (checkAttrsResponse.data) {
              attributesData.set('username', checkAttrsResponse.data.username);
            }
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
        } 
        else {
          console.info("Not authenticated, redirecting to Azure login.");
          attributesData.set('isAuth', false);
          const postLoginUrl = encodeURIComponent(window.location.href);
          window.location.href = `${constants.AzureSignIn}?postLoginRedirectURI=${postLoginUrl}`;
        }
        
        let params = {};
        this.profileId = this.$store.getters[GET_DATA]("username");
        if(this.profileId) {
          params = {
            id: this.profileId
          };
          const res = await axios.get(constants.EcomUserProfileURL, { params });
          if(res.data.ecomUserProfile) {
            this.accountNumbersList = res.data.ecomUserProfile.accountNumbers;
            this.currentlyManagingPId = res.data.ecomUserProfile.currentlyManagingPId;
          }
        } else {
          this.accountNumber = this.$store.getters[GET_DATA]("accountNumber");
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
  computed: {
    posts () {
      return this.$store.getters.posts
    }
  },
  created () {
    this.getAccountNumbers();
  }
}
</script>
<style>
#selectUser{
  border-style: none;
}
.user-label{
  padding-right: 17px;
  color: #0047BB !important;
}
</style>
