import Vuex from "vuex";
import generalModule from './modules/general.store';
import sessionModule from './modules/session.store';
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage
})

const store = new Vuex.Store({
    state: {},
    modules: {
      generalModule: generalModule,
      sessionModule: sessionModule,
    },
    mutations: {},
    getters: {},
    actions: {},
    plugins: [vuexLocal.plugin, vuexSession.plugin]
  });
export default store;