const SET_DATA = 'SET_DATA';
const GET_DATA = 'GET_DATA';
const RESET = 'RESET';
const ATTRIBUTE_RESET = 'ATTRIBUTE_RESET';

// State object
const initialState = () => ({
    storedValue : [],
});
const state = initialState();

// Getter functions
const getters = {
    [GET_DATA] : (state) => (key) => {
        if(state.storedValue[key] == undefined || state.storedValue[key] == null){
            state.storedValue[key] = localStorage.getItem(key);
        }
        return state.storedValue[key]
    }
}
// Actions 
const actions = {
    [RESET]({ commit }) {
        commit(RESET);
    },
  
    [SET_DATA](context, payload){
        context.commit(SET_DATA, payload);        
    },
    [ATTRIBUTE_RESET](context, payload){
        context.commit(ATTRIBUTE_RESET, payload);
    },
}

// Mutations
const mutations = {
    RESET(state) {
        localStorage.clear();
        Object.assign(state, initialState())
            
    },

    [SET_DATA](state, payload) {
        payload.attributes.forEach (function(value, key) {
            state.storedValue[key] =  value;
            localStorage.setItem(key, value);
        })
    },

    [ATTRIBUTE_RESET](state, payload) {
        payload.attributes.forEach (function(key) {
            localStorage.removeItem(key);
            state.storedValue[key]=null
        })        
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}