<template>
    <div id="loading_wrapper">
    <div class="loader_container">
      <div class="loader">
        <svg class="circular_loader" viewBox="25 25 50 50">
          <circle class="loader_path" cx="50" cy="50" r="20" fill="none" stroke-width="4" />
        </svg>
      </div>
    </div>
 </div>
</template>
<script>
export default {
};
</script>
<style scoped>
#loading_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  box-shadow: 0px 0px 9px -2px #000;
  z-index: 9999;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  backdrop-filter: blur(5px);
}

.loader_container {
  left: 50% !important;
  margin-left: -65px;
  position: fixed !important;
  top: 50% !important;
  margin-top: -100px;
  width: 45px;
  z-index: 9000 !important;
}

.loader_container .loader {
  position: relative;
  margin: 0px auto;
  width: 7em;
  height: 7em;
}

.loader_container .loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular_loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader_path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: square;
  stroke: #0047BB;

}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}


@keyframes color {
  0% {
    stroke: #0047BB;
  }

  40% {
    stroke: #0047BB;
  }

  66% {
    stroke: #0047BB;
  }

  80%,
  90% {
    stroke: #0047BB;
  }
}
</style>