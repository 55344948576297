<template>
  <!-- NEW, BRIGHTSPEED FOOTER -->
  <footer id="bspd-footer" class="whiteBack navbar navbar-expand-md" v-if="companyOwner == 4 && isBrightspeedToggleOn == 'true'">
    <div id="footer" class="collapse navbar-collapse">
      <div class="fitems" id="bspd-footer-logo">
        <a href="https://www.brightspeed.com/" alt="centurylink-logo">
          <img id="ctl-image" src="../assets/images/CtlLogo.svg" alt="ctlLogo">
        </a>
      </div>
      <div class="fitems align-self-start" id="footer-nav">
        <ul class="footer-list-row">
          <li>
            <a href="https://www.brightspeed.com/aboutus.html" target="_blank" rel="noopener">About Brightspeed</a>
          </li>
          <li>
            <a href="https://careers.smartrecruiters.com/Brightspeed" target="_blank" rel="noopener">Careers</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/brightspeed-news/" target="_blank" rel="noopener">Newsroom</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal.html" target="_blank" rel="noopener">Legal</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal/consumer/privacy-notice.html" target="_blank" rel="noopener">Privacy Notice</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal/consumer/tariff-library.html" target="_blank" rel="noopener">Tariffs</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/community/programs-for-customers-with-disabilities.html" target="_blank" rel="noopener">Customers with Disabilities</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/c/sitemap.html" target="_blank" rel="noopener">Site Map</a>
          </li>
        </ul>
        <ul class="footer-list-row">
          <li>
            <a href="https://www.brightspeed.com/" target="_blank" rel="noopener" id="residential">Residential</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/c/small-business" target="_blank" rel="noopener" class="smb-footer">Small Business</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/ew/enterprise/" target="_blank" rel="noopener" class="enterprise-footer">Enterprise</a>
          </li>
          <li>
            <span class="copyright-year">&copy; {{currentYear}}, CenturyLink. All Rights Reserved.</span>
            <span class="feedback-link">
              <a href="#" onclick="return !OOo.oo_feedback.show(event);" class="feedback">Feedback</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div id="mobile-footer" class="navbar-toggler">
      <div class="fitems" id="mobile-footer-list">
        <a :href="ctlLogoURL" alt="centurylink-logo">
          <img id="mobile-footer-logo" src="../assets/images/CtlLogo.svg" alt="mobileCtlLogo">
        </a>
        <ul id="mobile-footer-list-column">
          <li>
            <a href="https://www.brightspeed.com/aboutus.html" target="_blank" rel="noopener">About Brightspeed</a>
          </li>
          <li>
            <a href="https://careers.smartrecruiters.com/Brightspeed" target="_blank" rel="noopener">Careers</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/brightspeed-news/" target="_blank" rel="noopener">Newsroom</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal.html" target="_blank" rel="noopener">Legal</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal/consumer/privacy-notice.html" target="_blank" rel="noopener">Privacy Notice</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/legal/consumer/tariff-library.html" target="_blank" rel="noopener">Tariffs</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/aboutus/community/programs-for-customers-with-disabilities.html" target="_blank" rel="noopener">Customers with Disabilities</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/c/sitemap.html" target="_blank" rel="noopener">Site Map</a>
          </li>
          <div class="h-divider"></div>
          <li>
            <a href="https://www.brightspeed.com/" target="_blank" rel="noopener" id="residential-footer">Residential</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/c/small-business" target="_blank" rel="noopener" class="smb-footer">Small Business</a>
          </li>
          <li>
            <a href="https://www.brightspeed.com/ew/enterprise/" target="_blank" rel="noopener" class="enterprise-footer">Enterprise</a>
          </li>
          <li>
            <span class="copyright-year">&copy; {{currentYear}}, CenturyLink. All Rights Reserved.</span>
          </li>
          <li id="mobile-feedback">
            <span id="mobile-feedback-link">
              <a href="#" onclick="return !OOo.oo_feedback.show(event);" class="feedback">Feedback</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- END NEW BRIGHTSPEED FOOTER -->

  <!-- CENTURYLINK FOOTER -->
  <footer id="ctl-footer" class="whiteBack navbar navbar-expand-md" v-else>
    <div id="footer" class="collapse navbar-collapse">
      <div class="fitems" id="footer-logo">
        <a :href="ctlLogoURL" alt="centurylink-logo">
          <img id="ctl-logo-image" src="../assets/images/CtlLogo.svg" alt="ctlLogo">
        </a>
      </div>
      <div class="fitems align-self-start" id="footer-nav">
        <ul class="footer-list-row">
          <li>
            <a href="https://www.lumen.com/en-us/about.html" target="_blank" rel="noopener">About Lumen</a>
          </li>
          <li>
            <a :href="AboutUsURL" target="_blank" rel="noopener">About CenturyLink</a>
          </li>
          <li>
            <a href="https://jobs.lumen.com/" target="_blank" rel="noopener">Careers</a>
          </li>
          <li>
            <a href="https://ir.lumen.com/" target="_blank" rel="noopener">Investors Relations</a>
          </li>
          <li>
            <a href="https://news.lumen.com/" target="_blank" rel="noopener">Newsroom</a>
          </li>
          <li>
            <a :href="AboutUsLegalURL" target="_blank" rel="noopener">Legal</a>
          </li>
          <li>
            <a :href="LegalURL" target="_blank" rel="noopener">Legal Notices</a>
          </li>
          <li>
            <a :href="PrivacyNoticeURL" target="_blank" rel="noopener">Privacy Notice</a>
          </li>
          <li>
            <a :href="TariffLibraryURL" target="_blank" rel="noopener">Tariffs</a>
          </li>
        </ul>
        <ul class="footer-list-row">
          <li>
            <a :href="AboutUsDisabledURL" target="_blank" rel="noopener">Customers with Disabilities</a>
          </li>
          <li>
            <a :href="SiteMapURL" target="_blank" rel="noopener">Site Map</a>
          </li>
          <li>
            <a href="https://www.centurylink.com/local.html" id="ctl-area" target="_blank" rel="noopener">CenturyLink in Your Area</a>
          </li>
          <li>
            <a href="https://www.dexpages.com/" target="_blank" rel="noopener">White/Yellow Pages</a>
          </li>
          <li>
            <a href="https://www.getcenturylink.com/" target="_blank" rel="noopener">CenturyLink Retailer</a>
          </li>
        </ul>
        <ul class="footer-list-row">
          <li>
            <a :href="HomeURL" id="residential-footer" target="_blank" rel="noopener">Residential</a>
          </li>
          <li>
            <a :href="SmallBusinessURL" class="smb-footer" target="_blank" rel="noopener">Small Business</a>
          </li>
          <li>
            <a href="https://www.lumen.com/en-us/home.html" class="enterprise-footer" target="_blank" rel="noopener">Enterprise</a>
          </li>
          <li>
            <span class="copyright-year">&copy; {{currentYear}}, CenturyLink. All Rights Reserved.</span>
            <span class="feedback-link">
              <a href="#" onclick="return !OOo.oo_feedback.show(event);" class="feedback">Feedback</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div id="mobile-footer" class="navbar-toggler">
      <div class="fitems" id="mobile-footer-list">
        <a :href="ctlLogoURL" alt="centurylink-logo">
          <img id="mobile-footer-logo" src="../assets/images/CtlLogo.svg" alt="mobileCtlLogo">
        </a>
        <ul id="mobile-footer-list-column">
          <li>
            <a href="https://www.lumen.com/en-us/about.html" target="_blank" rel="noopener">About Lumen</a>
          </li>
          <li>
            <a :href="AboutUsURL" target="_blank" rel="noopener">About CenturyLink</a>
          </li>
          <li>
            <a href="https://jobs.lumen.com/" target="_blank" rel="noopener">Careers</a>
          </li>
          <li>
            <a href="https://ir.lumen.com/" target="_blank" rel="noopener">Investors Relations</a>
          </li>
          <li>
            <a href="https://news.lumen.com/" target="_blank" rel="noopener">Newsroom</a>
          </li>
          <li>
            <a :href="AboutUsLegalURL" target="_blank" rel="noopener">Legal</a>
          </li>
          <li>
            <a :href="LegalURL" target="_blank" rel="noopener">Legal Notices</a>
          </li>
          <li>
            <a :href="PrivacyNoticeURL" target="_blank" rel="noopener">Privacy Notice</a>
          </li>
          <li>
            <a :href="TariffLibraryURL" target="_blank" rel="noopener">Tariffs</a>
          </li>
          <li>
            <a :href="AboutUsDisabledURL" target="_blank" rel="noopener">Customers with Disabilities</a>
          </li>
          <li>
            <a :href="SiteMapURL" target="_blank" rel="noopener">Site Map</a>
          </li>
          <li>
            <a href="https://www.centurylink.com/local.html" id="ctl-in-area" target="_blank" rel="noopener">CenturyLink in Your Area</a>
          </li>
          <li>
            <a href="https://www.dexpages.com/" target="_blank" rel="noopener">White/Yellow Pages</a>
          </li>
          <li>
            <a href="https://www.getcenturylink.com/" target="_blank" rel="noopener">CenturyLink Retailer</a>
          </li>
          <div class="h-divider"></div>
          <li>
            <a :href="HomeURL" id="residential-link" target="_blank" rel="noopener">Residential</a>
          </li>
          <li>
            <a :href="SmallBusinessURL" class="smb-footer" target="_blank" rel="noopener">Small Business</a>
          </li>
          <li>
            <a href="https://www.lumen.com/en-us/home.html" class="enterprise-footer" target="_blank" rel="noopener">Enterprise</a>
          </li>
          <li>
            <span class="copyright-year">&copy; {{currentYear}}, CenturyLink. All Rights Reserved.</span>
          </li>
          <li id="mobile-feedback">
            <span id="mobile-feedback-link">
              <a href="#" onclick="return !OOo.oo_feedback.show(event);" class="feedback">Feedback</a>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- END CENTURYLINK FOOTER -->
</template>

<script>
import links from '../js/links';
import { GET_DATA as GET_STORE_DATA } from '../store/storecontants';
import { GET_DATA as GET_SESSION_DATA } from '../store/sessioncontants';
export default {
  name: 'FooterBar',

  data() {
    return {
      currentYear: new Date().getFullYear(),
      isBrightspeedToggleOn: false,
      AboutUsURL: links.AboutUsURL,
      AboutUsLegalURL: links.AboutUsLegalURL,
      ctlLogoURL: links.ctlLogoURL,
      LegalURL: links.LegalURL,
      TariffLibraryURL: links.TariffLibraryURL,
      AboutUsDisabledURL: links.AboutUsDisabledURL,
      SiteMapURL: links.SiteMapURL,
      HomeURL: links.HomeURL,
      SmallBusinessURL: links.SmallBusinessURL,
      PrivacyNoticeURL: links.PrivacyNoticeURL,
    }
  },
  methods: {
    isOwnerIdMatching (ownerIdChecking) {
      this.companyOwnerId = ownerIdChecking
      this.isBrightspeedToggleOn = process.env.VUE_APP_TSA_TOGGLE
    },
    getCompanyOwnerId () {
      return ((this.$store.getters[GET_STORE_DATA]('companyOwnerId') != null) ? this.$store.getters[GET_STORE_DATA]('companyOwnerId') : this.$store.getters[GET_SESSION_DATA]('companyOwnerId'));
    }
  },
  computed: {
		companyOwner () {
      const companyOwnerId = this.getCompanyOwnerId();
			this.isOwnerIdMatching(companyOwnerId);
			return companyOwnerId
		}
	},

  created() {
    /* MAIN OL ENGINE */
    let mainOlEngineScript = document.createElement('script')
    mainOlEngineScript.type = 'type/javascript'
    mainOlEngineScript.setAttribute('src', '../js/oo_engine.min.js')
    document.head.appendChild(mainOlEngineScript)
    /* FEEDBACK CONFIGURATION */
    let feedbackConfScript = document.createElement('script')
    feedbackConfScript.type = 'type/javascript'
    feedbackConfScript.setAttribute('src', '../js/oo_conf_inline.js')
    document.head.appendChild(feedbackConfScript)
  }
}
</script>

<style scoped>
#footer {
  margin: auto;
  position: relative;
  width: 990px;
  flex-basis: inherit;
  flex-grow: inherit;
  align-items: flex-start;
}
#ctl-image{
  height: 26.25px;
  width: 130.12px;
}
#ctl-logo-image {
  height: 26.25px;
  width: 130.12px;
}
#mobile-footer-logo{
  height: 26.25px;
  width: 130.12px;
  margin-bottom: 36.41px;
}
#footer-logo {
  margin-top: 36.98px;
  width: 195px;
}
#bspd-footer-logo {
  margin-top: 27.5px;
  width: 195px;
}
#footer-nav {
  font-size: 12px;
  height: 60px;
  line-height: 1.71;
  margin: 20.86px 0px;
  width: 795px;
}
ul.footer-list-row {
  color: black;
  list-style: none;
  width: 100%;
  font-family: Maison Neue, sans-serif;
  padding: 0px;
  margin-bottom: 0px;
  text-align: left;
}
ul.footer-list-row li {
  display: inline;
}
ul.footer-list-row li:after {
  content: '|';
  padding-left: 4px;
  padding-right: 4px;
}
ul.footer-list-row li:last-child:after {
  content: '';
}
.h-divider{
  width: calc(100% - 30px);
  width: -moz-calc(100% - 30px);
  width: -webkit-calc(100% - 30px);
  max-width: 352px;
  border-top: 1px solid #BEBEBE;
  margin: 15px auto 20px;
}
#mobile-footer{
  height: auto;
  width: 100%;
  text-align: -webkit-center;
}
#mobile-footer-list{
  margin-top: 21.55px;
  margin-bottom: 29.55px;
  width: 351.5px;
}
#mobile-footer-list-column{
  font-family: MaisonNeue-Book, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  list-style-type: none;
  padding: 0px;
}
#mobile-footer-list-column li a {
  color: #000000;
}
#footer a {
  color: black;
  line-height: 1.5;
}
#footer-nav a {
  font-size: 12px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.copyright-year {
  color: black;
  margin: 1em 0;
  text-align: center;
}
ul.footer-list-row li.last {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0px;
}
.feedback-link {
  float: right;
  font-size: 12px;
}
#mobile-feedback{
  margin-top: 20px;
}
#mobile-feedback-link, .feedback-link{
  background: transparent url(../assets/images/oo_icon.gif) right center
    no-repeat scroll;
}
.feedback {
  margin-right: 12px;
}
@media (max-width: 992px) {
  #bspd-footer-logo, #footer-logo {
    padding-right: 20px;
  }
  #footer-nav {
    height: auto;
  }
  #bspd-footer-logo, #footer-logo, #footer-nav, #footer {
    width: auto;
  }
  #footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1009px) {
  #bspd-footer, #ctl-footer {
    margin: 0 auto;
  }
  .feedback {
    margin-left: 12px;
  }
}

</style>
