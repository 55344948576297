<template>
  <header id="digipay-header" class="whiteBack">
    <nav class="navbar navbar-expand-md bg-body" id="top-header" aria-labelledby="topHeaderLabel">
      <div class="container img-specs" id="ctl-logo">
        <a :href="ctlLogoURL">
          <img class="navbar-brand" alt="ctl-logo" src="../assets/images/CtlLogo.svg">
        </a>
        
      </div>
      <div class="container me-0" id="mobile-icons">
        <a class="chatbot navbar-toggler" id="chatbot-icon" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat">
          <img id="chat-icon" src="../assets/images/icon-chat.svg" alt="Chat Support" data-galink-type="chat">
        </a>
        <button id="hamburger-icon" class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar" aria-controls="sidebar"><i class="material-icons">menu</i></button>
      </div>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link font-header" href="#" id="home-dropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">For Home</a>
            <ul class="dropdown-menu dropdown-menu-end header-dropdown" aria-labelledby="home-dropdown">
              <li><a class="dropdown-item" :href="InternetURL">Internet</a></li>
              <li><a class="dropdown-item" :href="TvURL">TV</a></li>
              <li><a class="dropdown-item" :href="PhoneURL">Phone</a></li>
              <li><a class="dropdown-item" :href="AccessoriesURL">Accessories</a></li>
              <li><a class="dropdown-item" :href="FiberURL">Fiber Internet</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link font-header" href="#" id="business-dropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">For Business</a>
            <ul class="dropdown-menu dropdown-menu-end header-dropdown" aria-labelledby="business-dropdown">
              <li><a class="dropdown-item" :href="SmallBusinessURL">Business Home</a></li>
              <li><a class="dropdown-item" :href="BusinessInternetURL">Internet</a></li>
              <li><a class="dropdown-item" :href="BusinessTvURL">TV</a></li>
              <li><a class="dropdown-item" :href="BusinessPhoneURL">Phone</a></li>
              <li><a class="dropdown-item" :href="BusinessFiberURL">Fiber Internet</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse" id="search-menu">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link font-header" href="#" id="support-dropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">Support</a>
            <ul class="dropdown-menu dropdown-menu-end header-dropdown" aria-labelledby="support-dropdown">
              <li><a class="dropdown-item" :href="HelpURL">For Home</a></li>
              <li><a class="dropdown-item" :href="SupportURL">For Business</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link font-header" href="#" id="sign-in-dropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">Sign In</a>
            <ul class="dropdown-menu dropdown-menu-end header-dropdown" aria-labelledby="sign-in-dropdown">
              <li><a class="dropdown-item" :href="MyaEntryPointURL">For Home</a></li>
              <li><a class="dropdown-item" :href="BusinessLoginURL">For Business</a></li>
              <li><a class="dropdown-item" href="/qbp/identify">Quick Bill Pay</a></li>
            </ul>
          </li>
          
        </ul>
        <form role="search" >
            <img
              tabindex="0"
              class="search-icon" 
              alt="search-icon"
              role="button" 
              src="../assets/images/icon-action-search_24px.png" 
              label="Toggle" 
              @click="toggle" 
              v-on:keyup.enter="toggle"
              />
            <OverlayPanel ref="op" :dismissable="true" >
              <div class="search-component">
                <img class ="search-icon-pop" alt="search-click" src="../assets/images/icon-action-grey-search_24px.png" @click="onSearchClick()"/>
                <label for="search" class="hidden-visually">Search: </label>
                <input ref="searchBox" id="search" class="search-box whiteBack font-header" 
                  v-model="Address" 
                  @keypress="searchbar" 
                  placeholder="Search bar"
                  />
              </div>
            </OverlayPanel>
          </form>
      </div>

      <div class="navbar-toggler offcanvas offcanvas-end" tabindex="-1" id="sidebar" aria-labelledby="sidebarLabel">
        <div class="offcanvas-header">
          <img id="mobile-search-icon" alt="mobile-search-icon" class ="cursor-pointer" v-if="showSearchIcon" src="../assets/images/icon-action-search_24px.png"  @click="onSearchIcon()"/>
          <img class ="left-arrow cursor-pointer" alt="back-tick" v-if="showBackTick" src="../assets/images/back-arrow.png" @click="onBackTick()"/>
          <button type="button" id="sidebar-close" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" @click="onCrossClick()"></button>
        </div>
        <div class="h-divider"></div>
        <div class="offcanvas-body">
          <div class="mobile-signout" v-if="showSearchBar">
            <img id="mobile-search-popup-icon" alt="mobile-search-popup-icon" class ="search-icon-pop" src="../assets/images/icon-action-search_24px.png" @click="onSearchClick()"/>
            <label for="search" class="hidden-visually">Search: </label>
            <input id="mobile-search-bar" ref="mobileSearch" class="whiteBack font-header" v-model="Address" @keypress="searchbar" placeholder="Search bar"/>
          </div>
        
          <div class="accordion accordion-flush" id="accordionFlushSidebar">
            <div class="accordion-item no-border">
              <h2 class="accordion-header" id="flush-forHome">
                <button class="font-header sidebar-button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  For Home
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-forHome" data-bs-parent="#accordionFlushSidebar">
                <div class="accordion-body">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a :href="InternetURL" class="d-flex align-items-start font-header">Internet</a></li>
                      <li><a :href="TvURL" class="d-flex align-items-start font-header">TV</a></li>
                      <li><a :href="PhoneURL" class="d-flex align-items-start font-header">Phone</a></li>
                      <li><a :href="AccessoriesURL" class="d-flex align-items-start font-header">Accessories</a></li>
                      <li><a :href="BusinessFiberURL" class="d-flex align-items-start font-header">Fiber Internet</a></li>            
                    </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item no-border">
              <h2 class="accordion-header" id="flush-forBusiness">
                <button class="font-header sidebar-button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  For Business
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-forBusiness" data-bs-parent="#accordionFlushSidebar">
                <div class="accordion-body">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a :href="SmallBusinessURL" class="d-flex align-items-start font-header">Business Home</a></li>
                      <li><a :href="BusinessInternetURL" class="d-flex align-items-start font-header">Internet</a></li>
                      <li><a :href="BusinessTvURL" class="d-flex align-items-start font-header">TV</a></li>
                      <li><a :href="BusinessPhoneURL" class="d-flex align-items-start font-header">Phone</a></li>
                      <li><a :href="BusinessFiberURL" class="d-flex align-items-start font-header">Fiber Internet</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-support">
                <button class="font-header sidebar-button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Support
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-support" data-bs-parent="#accordionFlushSidebar">
                <div class="accordion-body">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a :href="HelpURL" class="d-flex align-items-start font-header">For Home</a></li>
                    <li><a :href="SupportURL" class="d-flex align-items-start font-header">For Business</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-signIn">
                <button class="font-header sidebar-button accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                  Sign In
                </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-signIn" data-bs-parent="#accordionFlushSidebar">
                <div class="accordion-body">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><a :href="MyaEntryPointURL" class="d-flex align-items-start font-header">For Home</a></li>
                      <li><a :href="BusinessLoginURL" class="d-flex align-items-start font-header">For Business</a></li>
                      <li><a href="/qbp/identify" class="d-flex align-items-start font-header">Quick Bill Pay</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  
</template>

<script>
import { defineComponent } from 'vue';
import store from '../store';
import links from '../js/links';
import {
    GET_DATA
} from '../store/storecontants';

export default defineComponent({
  name: 'Header',
  components: {
  }, 
  data() {
    return {
      Address: '',
      showSearchBar: false,
      showBackTick: false,
      showSearchIcon: true,
      InternetURL: links.InternetURL,
      TvURL: links.TvURL,
      PhoneURL: links.PhoneURL,
      AccessoriesURL: links.AccessoriesURL,
      FiberURL: links.FiberURL,
      SmallBusinessURL: links.SmallBusinessURL,
      BusinessInternetURL: links.BusinessInternetURL,
      BusinessTvURL: links.BusinessTvURL,
      BusinessPhoneURL: links.BusinessPhoneURL,
      BusinessFiberURL: links.BusinessFiberURL,
      HelpURL: links.HelpURL,
      SupportURL: links.SupportURL,
      BusinessLoginURL: links.BusinessLoginURL,
      MyaEntryPointURL: links.MyaEntryPointURL,
      ctlLogoURL: links.ctlLogoURL
    }
  },
  methods: {
    toggle (event) {
      this.$refs.op.toggle(event)
      setTimeout(() => { 
        let errorDiv = this.$refs.searchBox
        if(errorDiv) {
          this.$refs.searchBox.focus();
        }
      }, 10)
    },
    searchbar: function (e) {
      if (e.keyCode === 13) {
        this.onSearchClick()
      }else if (e.keyCode === 37 || e.keyCode === 47) {
        //to restrict % and / in search
        e.preventDefault();
      }
    },
    onSearchIcon () {
      this.showSearchBar = true
      this.showSearchIcon = false
      this.showBackTick = true
      setTimeout(() => { 
        let errorDiv = this.$refs.mobileSearch
        if(errorDiv) {
          this.$refs.mobileSearch.focus();
        }
      }, 10)
      
    },
    onBackTick () {
      this.showSearchBar = false
      this.showSearchIcon = true
      this.showBackTick = false
    },
    onSearchClick () {
      this.$gtm.trackView('Searchbar from header', 'DigitalHome')
      window.dataLayer.push({
        event: 'gtm.click',
        action: 'click',
        label: 'In Support page Search function from header',
        page: {
          pageType: '<Searchbar>',
          pageName: 'Support'
        },
        value: this.Address,
        noninteraction: false
      })
      if (this.Address.length !== 0) {
        const searchQuery = this.Address.replace(/[\\$+?#{|[.()*]+/gm, '')
        const link = process.env.VUE_APP_CTL_STATIC_DOMAIN + '/home/search.' + searchQuery + '.html?search=' + searchQuery + '&search_submit=&start=0&limit=25&feed=residential'
        window.open(link, '_blank')
      }
    },
    onCrossClick () {
      this.showSearchBar = false
      this.showBackTick = false
      this.showSearchIcon = true
    }
  },
});

</script>

<style scoped>
  #mobile-icons {
    width: fit-content;
  }

  #chat-icon {
    height: 24px;
  }

  #chatbot-icon {
    margin-top: 22px;
  }

  .accordion-body {
    padding: 0.25rem 1.25rem 1rem 1.25rem;
  }

  .accordion-body ul {
    box-shadow: inset 2px 0 0px -1px rgb(0 0 0 / 13%);
  }

  #sidebar.navbar-toggler{
    font-size: 14px;
    padding: 0px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  #mobile-search-bar {
    margin: 0px;
    padding: 18px 16px 15px 56px;
    height: 52px;
    border: 1px solid black;
    border-radius: 4px;
    opacity: 1;
    width: 90%;
  }

  #mobile-search-popup-icon {
    position: absolute;
    left: 50px;
    top: 98px;
  }

  .left-arrow{
    width: 24px;
    height: 24px;
  }

  #mobile-search-icon {
    height: 24px;
    width: 24px;
    margin: 0px;
  }

  #sidebar-close.btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 1;
  }

  #sidebar-close.btn-close:focus{
    box-shadow: none;
  }

  .h-divider{
    margin-top:5px;
    margin-bottom:5px;
    height:1px;
    width:100%;
    border-top:1px solid rgba(0, 0, 0, 0.125);
  }

  .sidebar-button.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .sidebar-button.accordion-button {
    box-shadow: none!important;
  }

  .sidebar-button.accordion-button:not(.collapsed) {
    color: rgb(0,0,0);
    background-color: inherit;
    padding-bottom: 0.25rem;
  }

  .header-dropdown {
    width: 189px!important;
    padding: 0px 11.5px;
    border-top: 1px solid #BEBEBE;
    border-radius: 0px;
  }

  ul .header-dropdown li {
    border-top: 1px solid #BEBEBE;
  }

  a.dropdown-item:hover {
    text-decoration: none;
    background: #FFFFFF;
  }

  #hamburger-icon{
    border: none;
    padding: 0px 12px;
    margin-top: 27px;
  }

  #ctl-logo{
    padding: 0px;
    margin: 24px 0px 0px 24px;
    height: 32px;
    width: fit-content;
  }

  div.navbar-brand {
    padding: 0px;
    margin: 0px;
  }

  a.nav-link.font-header {
    padding: 0px 0px 0px 32px;
    margin-top: 32px;
  }

  .search-box{
    width:90%;
    margin-left: 24px;
    border: 1px solid black;
    border-radius: 4px;
    opacity: 1;
    padding: 14px 24px 12px 14px;
  }

  .search-icon-pop{
    height: 24px;
    width: 24px;
  }

  .search-icon{
    height: 24px;
    width: 24px;
    margin-top: 28px;
    margin-right:24px;
    margin-left: 24px;
    opacity: 1;
  }

  #top-header {
    padding-bottom: 24px;
    padding-top: 0px;
    box-shadow: 0px 2px 4px #00000033;
  }

  #top-header .dropdown-menu {
    box-shadow: 3px 3px 3px #0000004d;
    margin-top: 10px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
  }

  li a {
    display: block;
    color: black;
    padding: 14px 16px;
    text-decoration: none;
  }

  #digipay-header {
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  .navbar {
    font-size: 14px;
    font-weight: normal !important;
    color: black !important;
  }

  a:hover {
    text-decoration: none;
    color: rgb(0,0,0)!important;
  }

  .nav-link:focus {
    color: rgb(0,0,0);
  }

  .navbar-nav .dropdown-menu {
    width: 288px;
  }

  @media only screen and (max-width: 767px) {

    .search-box{
      margin: 0px;
      padding: 18px 16px 15px 56px;
      height: 52px;
    }

    #digipay-header {
      width: auto;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    #sidebar {
      width: 356px;
    }

  }

  @media (min-width: 768px) {
    
    #top-header li:first-child {
      border-top: 0px;
    }

    .search-box{
      width:90%;
      margin-left: 16px;
      margin-top: 0px;
      padding: 14px 24px 12px 14px
    }

    .search-component{
      position: relative;
    }
    
    .search-component img{
      position: absolute;
      right:20px;
      top:14px;
    }
  
  }
.hidden-visually {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}
</style>