import axios from 'axios'
import constants from "./constants";
import store from "../store";
import {
    GET_DATA, SET_DATA, ATTRIBUTE_RESET
} from "../store/sessioncontants";


async function resetTokensAttributes() {
    const tokenKeys = store.getters[GET_DATA]("tokenKeys");
    if (tokenKeys != null && tokenKeys != "") {
        store.dispatch(ATTRIBUTE_RESET, { attributes: tokenKeys.split(",") });
    }
}

const decodeToken = async function (token: string) {
    try {
        // const token = to.query.token != null ? to?.query?.token : to?.params?.token;
        while(token.includes('%20')){
            token = token.replace('%20', '+')
        }
        token = decodeURIComponent(token);
        const attributesData = new Map();
        if (token == "") {
            //reset the attributes if the token is empty
            resetTokensAttributes();
            attributesData.set('tokenError', "");
            attributesData.set('tokenKeys', "");
            attributesData.set('token', "");
            store.dispatch(SET_DATA, { attributes: attributesData });
        }

        if (token != null && token != "") {
            resetTokensAttributes();
            const params = {
                "token": token
            };
            const resp = await axios.post(constants.TokenizerURL, params);
            if (resp.data != null) {
                const data = resp.data;
                attributesData.set('token', token);
                if (data.status == 0 && data.attribList != null) {
                    const attributes = data.attribList;
                    const mapped = attributes.map(item => ({ [item.name]: item.value }));
                    const ObjResponse = Object.assign({}, ...mapped);
                    const tokenKeys: string[] = [];
                    Object.keys(ObjResponse).forEach((key) => {
                        attributesData.set(key, ObjResponse[key]);
                        tokenKeys.push(key);
                    });
                    attributesData.set('tokenError', "");
                    attributesData.set('tokenKeys', tokenKeys);
                } else {
                    console.error("Error decoding the token");
                    console.error(data?.errorInfo?.errorMessage);
                    attributesData.set('tokenError', data?.errorInfo?.errorMessage);
                }
                store.dispatch(SET_DATA, { attributes: attributesData });
            }
        }
    } catch (e) {
        console.error("Error calling jumpIn");
    }
}


export default {
    decodeToken,
}